import styled from 'styled-components/macro';
import {
  BORDER_RADIUS,
  SPACE_TIMES,
  SPACE_XL,
  SPACE_XS,
} from 'styles/StyleConstants';

export const AuthForm = styled.div`
  /* width: ${SPACE_TIMES(100)}; */
  width: 480px;
  /* padding: 68px 44px; */
  border-radius: 20px;
  /* padding: ${SPACE_XL} ${SPACE_XL} ${SPACE_TIMES(8)}; */
  /* margin-top: ${SPACE_TIMES(10)}; */
  background-color: ${p => p.theme.componentBackground};
  /* border-radius: ${BORDER_RADIUS}; */
  /* box-shadow: ${p => p.theme.shadowSider}; */

  .login_tab {
    padding-bottom: 75px;
    position: relative;
    cursor: pointer;
    & > div {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      & > div {
        flex: 1;
      }
    }
  }

  .login_box {
    padding: 60px 44px;
  }

  .title {
    padding-top: 20px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1D9AEE;
    img {
      margin-right: 12px;
      width: 40px;
    }
  }
  .ant-form-item {
    /* margin-bottom: ${SPACE_XL}; */
    margin-bottom: 18px;

    &.last {
      margin-bottom: 0;
    }
  }

  .ant-form-item-with-help {
    margin-bottom: ${SPACE_XS};
  }

  .ant-input,
  .ant-input-affix-wrapper {
    color: ${p => p.theme.textColorSnd};
    background-color: ${p => p.theme.bodyBackground};
    border-color: ${p => p.theme.bodyBackground};

    &:hover,
    &:focus {
      border-color: ${p => p.theme.bodyBackground};
      box-shadow: none;
    }

    &:focus {
      background-color: ${p => p.theme.emphasisBackground};
    }
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    background-color: ${p => p.theme.emphasisBackground};
    box-shadow: none;
  }


  .ant-input-affix-wrapper {
    border-radius: 8px;
    /* overflow: hidden; */
    border: 1px solid #DCDBDD;
    background-color: #FCFCFC;
    min-height: 48px;
    .ant-input {
      background-color: transparent;
    }
    .anticon-eye-invisible {
      margin-left: 6px;
    }
    .anticon-close-circle {
      margin: 1px;
      font-size: 13px;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

`;
