/**
 * Datart
 *
 * Copyright 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ControllerFacadeTypes } from 'app/constants';
import { boardDrillManager } from 'app/pages/DashBoardPage/components/BoardDrillManager/BoardDrillManager';
import { getControlOptionQueryParams } from 'app/pages/DashBoardPage/utils/widgetToolKit/chart';
import { FilterSearchParams } from 'app/pages/MainPage/pages/VizPage/slice/types';
import { shareActions } from 'app/pages/SharePage/slice';
import { ExecuteToken, ShareVizInfo } from 'app/pages/SharePage/slice/types';
import ChartDataSetDTO from 'app/types/ChartDataSet';
import { filterSqlOperatorName } from 'app/utils/internalChartHelper';
import { RootState } from 'types';
import { request2 } from 'utils/request';
import {errorHandle, getErrorMessage} from 'utils/utils';
import { boardActions } from '.';
import {getChartWidgetRequestParams, getTheWidgetRelatedHideColumns} from '../../../utils';
import { handleServerBoardAction } from './asyncActions';
import { selectBoardById, selectBoardWidgetMap } from './selector';
import {
  Banner,
  BoardState,
  ControllerWidgetContent,
  getDataOption, QuickQuery,
  ServerDashboard,
  VizRenderMode,
  Widget,
  WidgetData,
} from './types';
import {ChartStyleConfig, ChartStyleSectionGroup} from "../../../../../types/ChartConfig";
import {CloneValueDeep} from "../../../../../../utils/object";
import {QueryResult, TableItem} from "../../../../MainPage/pages/ViewPage/slice/types";
/**
 * @param ''
 * @description '先拿本地缓存，没有缓存再去服务端拉数据'
 */
export const getBoardDetail = createAsyncThunk<
  null,
  {
    dashboardRelId: string;
    filterSearchParams?: FilterSearchParams;
    vizToken?: ExecuteToken;
    shareToken?: string;
  }
>(
  'board/getBoardDetail',
  async (params, { getState, dispatch, rejectWithValue }) => {
    // 1 在 内存里找到就返回
    const dashboard = selectBoardById(
      getState() as { board: BoardState },
      params?.dashboardRelId,
    );
    if (dashboard) {
      return null;
    }
    if (params.vizToken) {
      await dispatch(
        fetchBoardDetailInShare({ ...params, vizToken: params.vizToken }),
      );
    } else {
      await dispatch(fetchBoardDetail(params));
    }
    return null;
  },
);

export const fetchBoardDetail = createAsyncThunk<
  null,
  {
    dashboardRelId: string;
    filterSearchParams?: FilterSearchParams;
  }
>('board/fetchBoardDetail', async (params, { dispatch, rejectWithValue }) => {
  const { data } = await request2<ServerDashboard>(
    `/viz/dashboards/${params?.dashboardRelId}`,
  );

  dispatch(
    handleServerBoardAction({
      data,
      renderMode: 'read',
      filterSearchMap: { params: params?.filterSearchParams },
    }),
  );

  return null;
});

export const fetchBoardDetailInShare = createAsyncThunk<
  null,
  {
    dashboardRelId: string;
    vizToken: ExecuteToken;
    filterSearchParams?: FilterSearchParams;
    shareToken?: string;
  }
>(
  'board/fetchBoardDetailInShare',
  async (params, { dispatch, rejectWithValue }) => {
    const { vizToken, shareToken } = params;
    const { data } = await request2<ShareVizInfo>({
      url: `shares/${shareToken}/viz`,
      method: 'POST',
      data: {
        authorizedToken: vizToken.authorizedToken,
      },
    });
    dispatch(
      shareActions.setExecuteTokenMap({
        executeToken: data.executeToken,
      }),
    );
    const serverBoard = data.vizDetail as ServerDashboard;

    dispatch(
      handleServerBoardAction({
        data: serverBoard,
        renderMode: 'share',
        filterSearchMap: {
          params: params.filterSearchParams,
          isMatchByName: true,
        },
      }),
    );

    return null;
  },
);
export const renderedWidgetAsync = createAsyncThunk<
  null,
  { boardId: string; widgetId: string; renderMode?: VizRenderMode },
  { state: { board: BoardState } }
>(
  'board/renderedWidgetAsync',
  async ({ boardId, widgetId, renderMode }, { getState, dispatch }) => {
    const widgetMapMap = selectBoardWidgetMap(getState());
    const widgetMap = widgetMapMap?.[boardId];
    const curWidget = widgetMap?.[widgetId];
    if (!curWidget) return null;
    // 1 widget render
    dispatch(boardActions.renderedWidgets({ boardId, widgetIds: [widgetId] }));
    // 2 widget getData
    dispatch(
      getWidgetData({ boardId: boardId, widget: curWidget, renderMode }),
    );
    return null;
  },
);

export const getWidgetData = createAsyncThunk<
  null,
  {
    boardId: string;
    widget: Widget;
    renderMode: VizRenderMode | undefined;
    option?: getDataOption;
  },
  { state: RootState }
>(
  'board/getWidgetData',
  ({ widget, renderMode, option }, { getState, dispatch }) => {
    const boardId = widget.dashboardId;
    dispatch(boardActions.renderedWidgets({ boardId, widgetIds: [widget.id] }));
    const widgetId = widget.id;
    switch (widget.config.type) {
      case 'chart':
        dispatch(
          getChartWidgetDataAsync({ boardId, widgetId, renderMode, option }),
        );
        return null;
      case 'controller':
        dispatch(getControllerOptions({ boardId, widgetId, renderMode }));
        return null;
      default:
        return null;
    }
  },
);
export const getChartWidgetDataAsync = createAsyncThunk<
  null,
  {
    boardId: string;
    widgetId: string;
    renderMode: VizRenderMode | undefined;
    option?: getDataOption;
  },
  { state: RootState }
>(
  'board/getChartWidgetDataAsync',
  async ({ boardId, widgetId, renderMode, option }, { getState, dispatch }) => {
    dispatch(boardActions.renderedWidgets({ boardId, widgetIds: [widgetId] }));
    const boardState = getState() as { board: BoardState };

    const widgetMapMap = boardState.board.widgetRecord;
    const widgetInfo =
      boardState.board?.widgetInfoRecord?.[boardId]?.[widgetId];
    const widgetMap = widgetMapMap[boardId];
    const curWidget = widgetMap[widgetId];

    if (!curWidget) return null;
    const viewMap = boardState.board.viewMap;
    const dataChartMap = boardState.board.dataChartMap;
    const boardLinkFilters =
      boardState.board.boardInfoRecord?.[boardId]?.linkFilter;
    const drillOption = boardDrillManager.getWidgetDrill({
      bid: curWidget.dashboardId,
      wid: widgetId,
    });
    let requestParams = getChartWidgetRequestParams({
      widgetId,
      widgetMap,
      viewMap,
      option,
      widgetInfo,
      dataChartMap,
      boardLinkFilters,
      drillOption,
    });
    if (!requestParams) {
      return null;
    }
    const requiredMsg = Object.values(widgetMap).flatMap(widget => {
      const content = (widget.config.content as ControllerWidgetContent)
      if(content?.required && !content.config.controllerValues.length && !boardState.board?.widgetInfoRecord?.[boardId]?.[widget.id]?.loading && boardState.board?.widgetInfoRecord?.[boardId]?.[widget.id]?.rendered) return widget.config.name
      return []
    })
    if(requiredMsg.length) {
      errorHandle(Error(requiredMsg.join("、") + "  为必选字段"))
      return null
    }
    let widgetData;
    try {
      dispatch(boardActions.setLoadingMap({ boardId: curWidget.dashboardId, count: 1 }))
      if (renderMode === 'read') {
        // console.log("executeToken8:" + JSON.stringify(requestParams));
        const { data } = await request2<WidgetData>({
          method: 'POST',
          url: `data-provider/execute`,
          data: requestParams,
        });
        widgetData = { ...data, id: widgetId };
      } else {
        const executeTokenMap = (getState() as RootState)?.share
          ?.executeTokenMap;

        const dataChart = dataChartMap[curWidget.datachartId];
        const viewId = viewMap[dataChart.viewId].id;
        const executeToken = executeTokenMap?.[viewId];
        // console.log("executeParam2:" + JSON.stringify(executeToken));
        const urlParams = document.location.href;
        // 系统链接
        let clientId;
        if (urlParams.indexOf('&user=') > -1) {
          clientId = localStorage.getItem('SHARE_SYSTEM_CLIENT_ID');
        } else {
          clientId = localStorage.getItem('SHARE_CLIENT_ID');
        }
        const { data } = await request2<WidgetData>({
          method: 'POST',
          url: `shares/execute`,
          params: {
            executeToken: executeToken?.authorizedToken,
            clientId: clientId
          },
          data: requestParams,
        });
        widgetData = { ...data, id: widgetId };
      }
      dispatch(
        boardActions.setWidgetData(
          filterSqlOperatorName(requestParams, widgetData) as WidgetData,
        ),
      );
      dispatch(
        boardActions.changePageInfo({
          boardId,
          widgetId,
          pageInfo: widgetData.pageInfo,
        }),
      );
      dispatch(
        boardActions.setWidgetErrInfo({
          boardId,
          widgetId,
          errInfo: undefined,
          errorType: 'request',
        }),
      );
      dispatch(boardActions.setLoadingMap({ boardId: curWidget.dashboardId, count: -1 }))
    } catch (error) {
      console.log("getErrorMessage(error): ", getErrorMessage(error))
      errorHandle({ message: getErrorMessage(error) })
      dispatch(boardActions.setLoadingMap({ boardId: curWidget.dashboardId, count: -1 }))
      dispatch(
        boardActions.setWidgetErrInfo({
          boardId,
          widgetId,
          errInfo: getErrorMessage(error),
          errorType: 'request',
        }),
      );

      // 不清除内容
      dispatch(
        boardActions.setWidgetData({
          id: widgetId,
          columns: [],
          rows: [],
        } as WidgetData),
      );
    }
    dispatch(
      boardActions.addFetchedItem({
        boardId,
        widgetId,
      }),
    );
    return null;
  },
);

export const refreshChartWidgetColumn = createAsyncThunk<
  null,
  {
    boardId: string;
    widgetId: string;
    renderMode: VizRenderMode | undefined;
    content: ControllerWidgetContent;
    controllerWidgetId: string;
  },
  { state: RootState }
  >(
  'board/refreshChartWidgetColumn',
  async ({ boardId, widgetId, renderMode, content, controllerWidgetId }, { getState, dispatch }) => {
    dispatch(boardActions.renderedWidgets({ boardId, widgetIds: [widgetId] }));
    const boardState = getState() as { board: BoardState };

    const widgetMapMap = boardState.board.widgetRecord;
    const widgetInfo =
      boardState.board?.widgetInfoRecord?.[boardId]?.[widgetId];
    const widgetMap = widgetMapMap[boardId];
    const curWidget = widgetMap[widgetId];

    if (!curWidget) return null;

    const { hideColumns } = getTheWidgetRelatedHideColumns({ chartWidget: curWidget, widgetMap, controllerWidgetId })
    const updateWidget = {...curWidget, tableHideColumn: hideColumns}

    if (content.config.controllerValues && content.config.controllerValues.includes("1")) {
      updateWidget.tableHideColumn = [];
    }

    try {
      dispatch(
        boardActions.updateWidget(
          updateWidget
        ),
      );
    } catch (error) {
      console.log("getErrorMessage(error): ", getErrorMessage(error))
    }
    dispatch(
      boardActions.addFetchedItem({
        boardId,
        widgetId,
      }),
    );
    return null;
  },
);

// 根据 字段获取 Controller 的options
export const getControllerOptions = createAsyncThunk<
  null,
  { boardId: string; widgetId: string; renderMode: VizRenderMode | undefined },
  { state: RootState }
>(
  'board/getControllerOptions',
  async ({ boardId, widgetId, renderMode }, { getState, dispatch }) => {
    console.log('getControllerOptions')
    dispatch(
      boardActions.renderedWidgets({
        boardId: boardId,
        widgetIds: [widgetId],
      }),
    );
    const boardState = getState() as { board: BoardState };
    const viewMap = boardState.board.viewMap;
    const widgetMapMap = boardState.board.widgetRecord;
    const widgetMap = widgetMapMap[boardId];
    const widget = widgetMap[widgetId];
    if (!widget) return null;
    const content = widget.config.content as ControllerWidgetContent;
    const config = content.config;
    if (!Array.isArray(config.assistViewFields)) return null;
    if (config.assistViewFields.length < 2) return null;

    const executeTokenMap = (getState() as RootState)?.share?.executeTokenMap;
    const [viewId, ...columns] = config.assistViewFields;
    // 如果当前组件时 TreeSelect 自动添加 id 和 tree_parent_id
    if(content.type === ControllerFacadeTypes.TreeSelect) {
      columns.push('ID', 'TREE_PARENT_ID');
    }
    const executeToken = executeTokenMap?.[viewId];

    const view = viewMap[viewId];
    if (!view) return null;
    const requestParams = getControlOptionQueryParams({
      view,
      columns: columns,
      curWidget: widget,
      widgetMap,
    });

    if (!requestParams) {
      return null;
    }
    let widgetData;
    try {
      if (executeToken && renderMode !== 'read') {
        const { data } = await request2<ChartDataSetDTO>({
          method: 'POST',
          url: `shares/execute`,
          params: {
            executeToken: executeToken?.authorizedToken,
          },
          data: requestParams,
        });
        widgetData = { ...data, id: widget.id };
      } else {
        const { data } = await request2<WidgetData>({
          method: 'POST',
          url: `data-provider/execute`,
          data: requestParams,
        });
        widgetData = { ...data, id: widget.id };
      }
      dispatch(
        boardActions.setWidgetData(
          filterSqlOperatorName(requestParams, widgetData) as WidgetData,
        ),
      );
      dispatch(
        boardActions.setWidgetErrInfo({
          boardId,
          widgetId,
          errInfo: undefined,
          errorType: 'request',
        }),
      );
    } catch (error) {
      dispatch(
        boardActions.setWidgetErrInfo({
          boardId,
          widgetId,
          errInfo: getErrorMessage(error),
          errorType: 'request',
        }),
      );
    }
    dispatch(
      boardActions.addFetchedItem({
        boardId,
        widgetId,
      }),
    );
    return null;
  },
);



export const refreshChartRichTextLink = createAsyncThunk<
  null,
  { boardId: string; widgetId: string[]; widget: Widget; },
  { state: RootState }
>(
  'board/refreshChartRichTextLink',
  async ({ boardId, widgetId, widget}, { getState, dispatch }) => {
    interface RichText {
      ops: {
        insert: string;
        attributes?: {link: string};
      }[];
    }

    const content = widget.config.content as ControllerWidgetContent;

    const boardState = getState() as { board: BoardState };
    const dataChartMap = boardState.board.dataChartMap;
    const widgetMapMap = boardState.board.widgetRecord;
    const widgetMap = widgetMapMap[boardId];
    widgetId.map(id => {
      const datachartId = widgetMap[id].datachartId;
      console.log("widget", widgetMap[id])
      var dataChart = CloneValueDeep(dataChartMap[datachartId]);

      const fieldIndex = content.relatedViews.findIndex(re => re.viewId === dataChart.viewId);
      if (fieldIndex != undefined && fieldIndex > -1) {
        const param = content.relatedViews[fieldIndex].fieldValue;
        const value = content.config.controllerValues.toString();
      }
      if (dataChart.config.chartGraphId === 'react-rich-text') {
        const linkStyleIndex = dataChart.config.chartConfig.styles?.findIndex(item => item.key === 'delta');
        if (linkStyleIndex != undefined && linkStyleIndex > -1 && dataChart.config.chartConfig.styles) {
          const index = dataChart.config.chartConfig.styles[linkStyleIndex].rows?.findIndex(item => item.key === 'richText');
          if (index != undefined && index > -1) {
            const value = CloneValueDeep((dataChart.config.chartConfig.styles[linkStyleIndex].rows as ChartStyleSectionGroup[])[index].value);
            const richText =  JSON.parse(value) as RichText;
            richText.ops.map(text => {
              if (text.attributes?.link) {
                // text.attributes.link = 'https://www.bilibili.com'
              }
              return text
            });
            (dataChart.config.chartConfig.styles[linkStyleIndex].rows as ChartStyleSectionGroup[])[index].value = JSON.stringify(richText);
            dispatch(boardActions.setDataChartToMap([dataChart]));
          }
        }
      }
    })
    return null;
  }
)

/**
 * 新增记录
 */
export const createUserWidgetConfig = (data: { dashboardId: string, widgetId: string, config: string }, resolve?: () => void) => {
  return request2<QueryResult>({
    url: `/settings/widget-config`,
    method: 'POST',
    data
  }).then(() => {
    resolve && resolve();
  }).catch(error => {
    errorHandle(error);return Promise.reject(error);
  })
}

/**
 * 新增记录
 */
export const createUserWidgetConfigForShare = (data: { user: string, dashboardId: string, widgetId: string, config: string }, resolve?: () => void) => {
  return request2<QueryResult>({
    url: `/shares/widget-config`,
    method: 'POST',
    data
  }).then(() => {
    resolve && resolve();
  }).catch(error => {
    errorHandle(error);return Promise.reject(error);
  })
}

export const getVizBanners = createAsyncThunk<Banner[], string>(
  'Board/getVizBanners',
  async vizId => {
    const requestParams = { showBanner: 1 };
    try {
      const { data } = await request2<Banner[]>({
        method: 'POST',
        url: `viz/getBanners`,
        data: requestParams,
      });
      return data;
    } catch (error) {
      errorHandle(error);
      throw error;
    }
  },
);

export const createUserDashboardQueryConfig = (data: { user: string, dashboardId: string, name: string, config: string, isDefault: number, id?: string }, resolve?: () => void) => {
  return request2<QueryResult>({
    url: `/settings/dashboard-query-config`,
    method: 'POST',
    data
  }).then(() => {
    resolve && resolve();
  }).catch(error => {
    errorHandle(error);return Promise.reject(error);
  })
}

export const deleteUserDashboardQueryConfig = (id, resolve?: () => void) => {
  return request2<QueryResult>({
    url: `/settings/dashboard-query-config/${id}`,
    method: 'DELETE',
  }).then(() => {
    resolve && resolve();
  }).catch(error => {
    errorHandle(error);return Promise.reject(error);
  })
}

export const getUserDashboardQueryConfigs = createAsyncThunk<QuickQuery[], { dashboardId: string, user: string }>(
  'Board/getUserDashboardQueryConfigs',
  async ({ dashboardId, user}) => {
    const requestParams = { dashboardId, user };
    try {
      const { data } = await request2<QuickQuery[]>({
        method: 'POST',
        url: `/settings/dashboard-query-config-list`,
        data: requestParams,
      });
      return data;
    } catch (error) {
      errorHandle(error);
      throw error;
    }
  }
)
