import antd_en_US from 'antd/lib/locale/en_US';
import antd_zh_CN from 'antd/lib/locale/zh_CN';
import antd_id_ID from 'antd/lib/locale/id_ID';
import { StorageKeys } from 'globalConstants';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { initReactI18next } from 'react-i18next';
import { instance as requestInstance } from 'utils/request';
import en from './en/translation.json';
import { convertLanguageJsonToObject } from './translations';
import zh from './zh/translation.json';
import idn from './idn/translation.json';

export const translationsJson = {
  en: {
    translation: en,
  },
  zh: {
    translation: zh,
  },
  idn: {
    translation: idn,
  }
};

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(en);

export const changeLang = lang => {
  localStorage.setItem(StorageKeys.Locale, lang);
  window.location && window.location.reload();
};

const initialLocale = getInitialLocale();
requestInstance.defaults.headers['Accept-Language'] =
  initialLocale === 'zh' ? 'zh-CN' : 'en-US'; // FIXME locale
moment.locale(initialLocale === 'zh' ? 'zh-cn' : 'en-us'); // FIXME locale

export const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: initialLocale,
    resources: translationsJson,
    fallbackLng: 'en',
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export const antdLocales = {
  en: antd_en_US,
  zh: antd_zh_CN,
  idn: antd_id_ID
};

function getInitialLocale() {
  const storedLocale = localStorage.getItem(StorageKeys.Locale);

  // 如果有存储的语言环境，优先使用它
  if (storedLocale) {
    return storedLocale;
  }

  // 如果 localStorage 中没有语言环境，则根据域名来设置
  if (window.location.hostname === 'xxx') {
    const browserLocale = 'en';
    localStorage.setItem(StorageKeys.Locale, browserLocale);
    return browserLocale;
  }

  // 如果都没有，使用浏览器的语言环境
  const browserLocale = getBrowserLocale();

  localStorage.setItem(StorageKeys.Locale, browserLocale);
  return browserLocale;
}

function getBrowserLocale() {
  const lang = navigator.language;

  // 中文（包括简体和繁体）
  if (['zh', 'zh-CN', 'zh-TW'].includes(lang)) {
    return 'zh';
  }

  // 印尼语
  if (['id', 'id-ID'].includes(lang)) {
    return 'idn';
  }

  // 英文（包含美式和英式）
  if (['en', 'en-US', 'en-GB'].includes(lang)) {
    return 'en';
  }

  // 默认返回英文
  return 'zh';
}
